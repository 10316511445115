<template>
    <modal ref="modalRegistrarPago" v-loading="loading" titulo="Registrar Pago" tamano="modal-lg" no-aceptar adicional="Pagar" @adicional="pagoLeecheroManual()">
        <div class="row mx-0 justify-center">
            <div class="col-6 text-general f-15">
                <p class="text-general f-14 pl-3">Seleccionar forma de pago</p>
                <el-select v-model="tipoPago" class="w-100" @change="buscarCaja()">
                    <el-option
                    v-for="data in formasPago"
                    :key="data.id"
                    :value="data.id"
                    :label="data.tipo"
                    />
                </el-select>
            </div>
            <div class="col text-general f-15">
                <template v-if="tipoPago != 3">
                    <p class="text-general f-14 pl-3">Caja / Cuenta bancaria</p>
                    <el-select v-model="caja" class="w-100">
                        <el-option
                        v-for="data in cajas"
                        :key="data.id"
                        :value="data.id"
                        :label="data.nombre"
                        />
                    </el-select>
                </template>
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <div class="col-6">
                <div class="mt-2">
                    <p class="text-general f-14 pl-3">Valor a pagar</p>
                    <input-miles v-model="valorPagar" class="w-100" :money-options="money" />
                </div>
                <div class="mt-2">
                    <p class="text-general f-14 pl-3">Comentario</p>
                    <el-input v-model="comentarioPago" type="textarea" maxlength="300" rows="4" />
                </div>
            </div>
            <div class="col">
                <div class="mt-2">
                    <slim-cropper v-show="tipoPago == 2" ref="fotoTransferencia" :options="slimOptions" class="border cr-pointer" style="height:300px;width:300px;background-color:#F5F5F5;border-radius:6px;" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import pagos from '~/services/pagos/pagos'
export default {
    data(){
        return  {
            formasPago: [
                { id: 1, tipo: 'Efectivo' },
                { id: 2, tipo: 'Transferencia' },
                { id: 3, tipo: 'Por comisión' },
            ],
            comentarioPago: '',
            tipoPago: 1,
            money:{
                decimal: ',',
                thousands: '.',
                prefix: '$ ',
                precision: 2,
                masked: false
            },
            cajas: [],
            caja: '',
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            valorMaximo: '',
            valorPagar: 0,
            datos: {},
            loading:false
        }
    },
    computed:{
        precision(){
            return this.monedas.find(o=>o.id == this.datos.idm_moneda).decimales
        }
    },
    methods: {
        toggle(datos){
            this.datos = _.cloneDeep(datos)
            this.valorMaximo = this.datos.total_debe
            this.valorPagar = this.datos.total_debe
            this.money.precision = this.precision
            this.comentarioPago = ''
            this.tipoPago = 1;
            this.cajas = []
            this.caja = ''
            this.buscarCaja()
            this.$refs.modalRegistrarPago.toggle()
        },
        async buscarCaja(){
            try {
                if(!this.tipoPago || this.tipoPago == 3) return
                const params = {
                    pedido: this.datos.id_pedido,
                    tipo: this.tipoPago
                }
                const { data } = await pagos.buscarCaja(params)
                this.cajas = data
                this.caja = ''

            } catch (error){
                this.error_catch(error)
            }
        },
        async pagoLeecheroManual(){
            try {
                this.loading = true
                if(this.valorPagar == 0) return this.notificacion('Advertencia', 'El valor debe ser mayor a cero', 'warning')
                if(this.valorPagar > this.valorMaximo) return this.notificacion('Advertencia', `El valor máximo a pagar es de ${this.valorMaximo}`, 'warning')

                if(this.tipoPago != 3 && !this.caja) return this.notificacion('Error', 'Debe seleccionar una caja o una cuenta bancaria', 'error')

                const { image: foto } = this.$refs.fotoTransferencia.instanciaCrop.dataBase64.output

                const form = {
                    id_pedido_admin: this.datos.id_pedido,
                    valor: this.valorPagar,
                    comentario: this.comentarioPago,
                    tipo_efectivo: this.tipoPago,
                    id_caja: this.caja,
                    foto
                }

                const { data } = await pagos.pagoLeecheroManual(form)
                this.notificacion('','Pago Registrado','success')
                this.$emit('saved')
                this.valorPagar = 0
                this.$refs.modalRegistrarPago.toggle()
            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false
            }
        },
    }
}
</script>
