import axios from "axios"


const Pagos = {
    pagoLeechero: params => axios.get('pagos/pagos-leechero', { params }),
    pagoCliente: params => axios.get('pagos/pagos-cliente', { params }),
    detallePago: params => axios.get('pagos/detalle-pago', { params }),
    registarPagoCliente: data => axios.post('pagos/registrar-pago',  data ),
    // registarPagoLeechero: data => axios.post('pagos/registrar-pago-leechero',  data ),
    anularCreditoCliente: id => axios.put(`pagos/anular-pago-cliente/${id}`),
    anularCreditoLeechero: id => axios.put(`pagos/anular-pago-leechero/${id}`),
    buscarCaja: params => axios.get('pagos/buscar-caja', { params }),
    guardarTransferencia: data => axios.post('pagos/guardar-transferencia',  data ),





    //---------------------Aca empiezan mis rutas att:Yesid
    datos_entrega(form){
        return axios.post(`pagos/payu/datos/entrega`,form)
    },
    initPagoLeechero(form){
        return axios.post('pagos/payu/data/cuota',  form )
    },
    pagoLeecheroManual(form){
        return axios.post('pagos/registrar-pago-leechero',  form )
    },
    initPagoPedido(form){
        return axios.post('pagos/payu/data/pedido',  form )
    },

}

export default Pagos
